import moment from "moment";

const dateFormatString = "DD.MM.YYYY";
const dateTimeFormat = "DD.MM.YYYY HH:mm";
const dateWeekFormat = "W";

const isValidDate = date => date !== undefined && moment(date).isValid();

export const getFormattedDate = date =>
  isValidDate(date) ? moment(date).format(dateFormatString) : "-";
export const getFormattedDateTime = date =>
  isValidDate(date) ? moment(date).format(dateTimeFormat) : "-";
export const getDateWeekNumber = date =>
  isValidDate(date) ? moment(date).format(dateWeekFormat) : "-";
export const isDateLessThanDayAgo = date =>
  isValidDate(date)
    ? moment(date).isAfter(moment().subtract(24, "hours"))
    : false;
